<template>
  <div class="cash-coupon-manager">
    <!-- 优惠券管理 兑换码 理发放 -->
    <x-dialog :proxy="dialogRef" class="grant ">
      <el-tabs v-model="currentTab" @tab-click="tabChange">
        <el-tab-pane label="兑换码管理">
          <en-table-layout :table-data="CarmiTable">
            <template slot="toolbar">
              <el-input style="width: 220px" size="medium" clearable v-model="coupon_key"
                placeholder="请输入兑换码进行搜索"></el-input>

              <el-button class="ml-3" @click="handleClickSousuo" type="primary" size="small">搜索</el-button>

              <div class="col"></div>

              <el-button @click="addCardPassword" size="small" type="primary">生成兑换码</el-button>
            </template>

            <template slot="table-columns">
              <!-- 序号 -->
              <el-table-column label="序号" width="50">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>

              <el-table-column label="生成方式">
                <template slot-scope="scope">{{ scope.row.create_type === 1 ? '系统生成' : '表格导入' }}</template>
              </el-table-column>

              <el-table-column label="生成时间" width="260">
                <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
              </el-table-column>

              <el-table-column prop="key_num" label="兑换码总数" />
              <el-table-column prop="not_active_num" label="未激活数" />
              <el-table-column prop="active_num" label="已激活数" />
              <el-table-column prop="forbidden_num" label="已禁用数" />
              <el-table-column prop="exchange_num" label="已领取数" />
              <el-table-column prop="use_num" label="已使用数" />
              <el-table-column prop="expire_num" label="已过期数" />
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="handleCarmiTableDetailrow(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </template>

            <el-pagination slot="pagination" @size-change="handleCarmiTableSizeChange"
              @current-change="handleCarmiTableCurrentChange" :current-page="CarmiParams.page_no"
              :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :page-size="CarmiParams.page_size"
              :total="CarmiParams.total"></el-pagination>
          </en-table-layout>
        </el-tab-pane>

        <el-tab-pane label="在线发放" lazy>
          <en-table-layout :table-data="grantList.data">
            <template slot="toolbar">
              <el-input style="width: 220px" size="medium" clearable v-model="coupon_key"
                placeholder="请输入兑换码进行搜索"></el-input>

              <el-button class="ml-3" @click="handleClickSousuo" type="primary" size="small">搜索</el-button>

              <div class="col"></div>

              <el-button @click="handleClickXinjian" size="small" type="primary">新建发放</el-button>
            </template>

            <template slot="table-columns">
              <el-table-column :key="'num'" label="序号" width="50">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>

              <el-table-column :key="'source'" label="发放名单来源">
                <template slot-scope="scope">{{ scope.row.create_type === 1 ? '系统用户' : '表格导入' }}</template>
              </el-table-column>

              <el-table-column :key="'addtime'" label="生成时间" width="160">
                <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
              </el-table-column>

              <!-- <el-table-column
                :key="'group_name'"
                prop="group_name"
                label="所属客户组"
              ></el-table-column>-->
              <el-table-column prop="key_num" label="兑换码总数"></el-table-column>
              <el-table-column prop="not_active_num" label="未激活数"></el-table-column>
              <el-table-column prop="exchange_num" label="已领取数"></el-table-column>
              <el-table-column prop="forbidden_num" label="已禁用数"></el-table-column>
              <el-table-column prop="use_num" label="已使用数"></el-table-column>
              <el-table-column prop="expire_num" label="已过期数"></el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button @click="handleCarmiTableDetailrow(scope.row)" size="mini" type="primary">查看</el-button>
                </template>
              </el-table-column>
            </template>

            <el-pagination slot="pagination" @size-change="handlegrantListSizeChange"
              @current-change="handlegrantListCurrentChange" :current-page="grantList.page_no"
              :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :page-size="grantList.page_size"
              :total="grantList.data_total"></el-pagination>
          </en-table-layout>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>

    <!--兑换码管理—— 查看 -->
    <x-dialog :proxy="viewCardDialogRef">
      <en-table-layout @selection-change="CarmiTableListSelectChange" :table-data="CarmiTableList.data"
        :row-key="row => row.id" ref="viewCardTable">
        <template slot="toolbar">
          <el-form-item label="状态">
            <el-select clearable style="width: 100px" v-model="CarmiTableParams.status" size="medium">
              <el-option v-for="item in CarmiTableListOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="卡号区间">
            <el-input
              style="width: 150px"
              v-model="cardIntervalList.card_code_start"
              placeholder="请输入开始卡号"
              clearable
            />—
            <el-input
              style="width: 150px"
              v-model="cardIntervalList.card_code_end"
              placeholder="请输入结束卡号"
              clearable
            />
            <div class="card-code-prompt-text" v-text="promptText"></div>
          </el-form-item> -->
          <el-form-item>
            <el-input v-model="CarmiTableParams.search_value" placeholder="请输入关键字进行搜索" size="medium" clearable>
              <el-select v-model="CarmiTableParams.search_type" style="width: 90px" slot="prepend">
                <el-option label="券码ID" value="coupon_code"></el-option>
                <el-option label="兑换码" value="coupon_key"></el-option>
                <el-option label="手机号" value="binding_member_mobile"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-button @click="getLookList(true)" size="small" type="primary">搜索</el-button>

          <div class="col"></div>

          <div :style="{ marginTop: promptText ? '22px' : '12px', width: '100%' }">
            <!-- <el-button v-show="currentTab === '0'" @click="intervalToActivate(1)" type="primary" size="small">
              区间激活
            </el-button>
            <el-button v-show="currentTab === '0'" @click="intervalToActivate(0)" type="primary" size="small">
              区间禁用
            </el-button>-->
            <el-button @click="handleActivationCheck(0)" type="primary" size="small">激活选中</el-button>

            <el-button @click="handleActivationCheck(1)" type="primary" size="small">禁用选中</el-button>

            <el-button @click="handleActivationAll(0)" type="primary" size="small">激活全部</el-button>

            <el-button @click="handleActivationAll(1)" type="primary" size="small"
              :loading="cancelActivatedAllLoading">禁用全部</el-button>

            <el-button @click="handleClickRecord" type="primary" size="small">操作日志</el-button>
            <el-button @click="downDataTemplate" type="primary" size="small">下载延期数据模板</el-button>
            <!-- <el-button @click="$refs.updataExcel.click" type="primary" size="small">导入延期数据</el-button> -->

            <upload-excel-component class="updataExcel" text="导入延期数据" defaultSize :on-success="uploadExcelSuccess" />

            <el-button size="small" @click="ExportGetrecordList1()" type="primary"
              :loading="exportAllLoading">导出全部</el-button>
          </div>

          <span v-if="exportAllLoading">
            提示：正在导出
            <span id="spinner">...</span>
          </span>
        </template>

        <template slot="table-columns">
          <el-table-column :key="'selection'" :reserve-selection="true" type="selection" width="50" fixed />
          <!-- 序号 卡号 卡密 绑定用户 优惠券余额 状态 业务员 操作 -->
          <!-- 编号 卡号 卡密 套餐礼包 兑换时间 状态 所属销售公司 业务员 操作-->
          <!-- 1  卡密    2  发放  -->
          <el-table-column :key="'code'" label="编号" width="60" fixed>
            <template slot-scope="scope">{{ scope.$index + 1 + (CarmiTableParams.page_no - 1) * CarmiTableParams.page_size
            }}</template>
          </el-table-column>
          <el-table-column prop="coupon_code" label="券码ID" width="100" fixed></el-table-column>
          <el-table-column prop="coupon_key" label="兑换码" width="100"></el-table-column>
          <el-table-column prop="binding_member_mobile" label="领用用户手机号" width="120">
            <template slot-scope="scope">
              <div>
                <el-tooltip v-if="scope.row.binding_member_mobile" class="item" effect="dark"
                  :content="scope.row.binding_member_mobile" placement="top">
                  <span v-text="scope.row.binding_member_mobile"></span>
                </el-tooltip>
                <span v-else>————</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="领取时间" width="180">
            <template slot-scope="scope">
              <div>
                <el-tooltip v-if="scope.row.binding_time" class="item" effect="dark"
                  :content="scope.row.binding_time | unixToDate('yyyy-MM-dd hh:mm:ss')" placement="top">
                  <span>{{ scope.row.binding_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</span>
                </el-tooltip>
                <span v-else>————</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="使用时间" width="180">
            <template slot-scope="scope">
              <div>
                <el-tooltip v-if="scope.row.use_time" class="item" effect="dark"
                  :content="scope.row.use_time | unixToDate('yyyy-MM-dd hh:mm:ss')" placement="top">
                  <span>{{ scope.row.use_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</span>
                </el-tooltip>
                <span v-else>————</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="兑换码有效期" min-width="300">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.start_time">
                  {{ scope.row.start_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}至{{
                    scope.row.end_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
                </span>
                <span v-else>————</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="'statuses'" prop="statuses" label="状态" width="70">
            <template slot-scope="scope">
              <!-- 0:未激活/1:已激活 3:已兑换 4:已使用 6:已禁用 -->
              <!-- 状态 0:未激活 1:已激活 3:已兑换 4:已使用 6:已禁用 7:已过期 -->
              {{+scope.row.exp_status ? '已过期' :
                scope.row.status === 1
                  ? '已激活'
                  : scope.row.status === 3
                    ? '已领取'
                    : scope.row.status === 4
                      ? '已使用'
                      : scope.row.status === 6
                        ? '已禁用' : scope.row.status === 7
                          ? '已过期'
                          : '未激活'
              }}
            </template>
          </el-table-column>
          <el-table-column prop="company" label="所属销售公司" width="120">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.company" class="item" effect="dark" :content="scope.row.company"
                placement="top">
                <span class="tooltip-text-32" v-text="scope.row.company"></span>
              </el-tooltip>
              <span v-else>————</span>
            </template>
          </el-table-column>

          <el-table-column prop="active_salesman" label="业务员" width="120">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.active_salesman" class="item" effect="dark" :content="scope.row.active_salesman"
                placement="top">
                <span v-text="scope.row.active_salesman" class="tooltip-text-32"></span>
              </el-tooltip>
              <span v-else>————</span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="active_name"
            label="激活人"
          ></el-table-column>-->

          <el-table-column label="操作" fixed="right" min-width="160">
            <template slot-scope="scope">
              <!-- 状态 0:未激活 1:已激活 3:已兑换 4:已使用 6:已禁用 7:已过期 -->
              <el-button size="mini" type="primary" @click="handleCarmiTableListActivation(scope.row, scope.row.status)"
                v-if="!+scope.row.exp_status && (scope.row.status === 0 || scope.row.status === 6 || scope.row.status === 1 || scope.row.status === 3)">{{
                  scope.row.status === 0 || scope.row.status === 6 ? '激活' : '禁用' }}</el-button>
              <!-- 1  已激活：取消激活    2 未激活：激活   3 已绑定:禁用     4 禁用中:启用  -->
              <el-button size="mini" type="primary" @click="handleCarmiTableListActivation(scope.row, 'postpone')"
                v-if="scope.row.status === 6 || scope.row.status === 1 || scope.row.status === 3 || scope.row.status === 7">{{
                  '延期' }}</el-button>
            </template>
          </el-table-column>
        </template>

        <el-pagination slot="pagination" @size-change="handleCarmiTableListSizeChange"
          @current-change="handleCarmiTableListCurrentChange" :page-size="CarmiTableList.page_size"
          :current-page="CarmiTableList.page_no" :layout="MixinTableLayout" :page-sizes="MixinPageSizes" background
          :total="CarmiTableList.data_total"></el-pagination>
      </en-table-layout>
    </x-dialog>

    <!-- 生成兑换码 -->
    <x-dialog :proxy="addCardPasswordDialog" :class="[dialogVisible ? 'publishOnlineDialogDisableOk' : '']">
      <el-tabs v-if="addCardPasswordDialog.visible" v-model="createCarmiType" type="card">
        <el-tab-pane label="系统生成兑换码" name="1">
          <div class="tipBox">
            <p>*输入生成数量，即可一键生成兑换；</p>
            <p>*生成的兑换码默认为都未激活状态，需手动激活后方可使用；</p>
          </div>
          <div class="addMealForm">
            <el-form :model="submitCreateCardKeysParams" :rules="submitCreateCardKeysRules"
              ref="submitCreateCardKeysParams" label-width="110px">
              <el-form-item class="w200" label="生成数量" prop="num">
                <el-input-number :min="1" :max="5000" style="width: 200px" :controls="false"
                  v-model="submitCreateCardKeysParams.num" placeholder="请输入1-5000的正整数"></el-input-number>张
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入兑换码" name="2">
          <div>
            <div class="tipBox b1 tipBoxStyle">
              <p>*可导入券码ID + 兑换码 或 仅兑换码</p>
              <p>*券码ID和兑换码都不可重复</p>
              <p>*导入的兑换码都默认为未激活状态</p>
              <p>*券码ID为6~16位按顺序生成的数字（超过6位后自动进位）</p>
              <p>*兑换码为6~16位字母、数字随机生成，可纯字母、纯数字。字母区分大小写；</p>
              <p>*导入的内容不可超过5000条数据；</p>
            </div>
            <div class="shep">第一步：根据需求，勾选下列内容，形成表格模板</div>
            <el-radio-group v-model="downLoadType" @input="clearnUploadFiles">
              <el-radio :label="0">仅兑换码</el-radio>
              <el-radio :label="1">券码ID + 兑换码</el-radio>
            </el-radio-group>
            <br />
            <el-button style="margin-top: 10px; margin-bottom: 15px" type="default" size="small"
              @click="downTemplate">下载表格模板</el-button>
            <div class="shep">第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式</div>
            <div class="shep">第三步：导入表格</div>
            <div class="add-camilo-file-box" style="margin-bottom:7px">
              <upload-excel-component defaultSize :on-success-tem="excelSuccess"
                @upFileName="e => { this.tooltipContent = e }" />
              <el-tooltip v-if="tooltipContent" class="item" effect="dark" :content="tooltipContent"
                placement="top-start">
                <el-button class="add-camilo-file">{{ tooltipContent }}<i @click="clearnUploadFiles"
                    style="margin-left:6px" class="el-icon-circle-close"></i></el-button>
              </el-tooltip>
            </div>
            <div v-if="dialogVisible" style="margin-bottom: 10px;">
              <p>提示：当前文件中，存在无法正常导入的数据，请修改后重新上传文件</p>
              <p style="color: #007bff;cursor: pointer;" @click="_ => downloadError()"><i class="el-icon-download"></i>{{
                `《${tooltipContent.split('.x')[0]}》- 问题数据_` }}{{ Date.now() / 1000 | unixToDate('yyyyMMddhhmm') }}.xlsx
              </p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>
    <!-- 延期操作 -->
    <x-dialog :proxy="postpone">
      <div class="addMealForm">
        <el-form :model="postponeVal" ref="postpone" label-width="110px" :rules="postponeRules">
          <el-form-item class="w200" prop="num" label="延期时间">
            <el-input-number :min="1" :max="5000" style="width: 200px" :controls="false" v-model="postponeVal.num"
              placeholder="请输入延期天数"></el-input-number>天
          </el-form-item>
          <div class="tipBox" style="padding-left: 40px;">
            <p>提示：要延长的时间将会从设置完保存的那一刻开始计算。</p>
          </div>
        </el-form>
      </div>
    </x-dialog>
    <!-- 导入延期操作提示 -->
    <x-dialog :proxy="postponeTip">
      <div class="tipContent">
        <div class="tipContent-text">{{ postponeTipText }}</div>
        <div class="tipContent-button">
          <el-button type="primary" size="small" @click="downloadErrData">下载失败数据</el-button>
        </div>
      </div>
    </x-dialog>
    <!-- <el-dialog title="提示" :visible.sync="postponeTip" width="660px">
      <div class="tipContent">
        <div>{{ postponeTipText }}</div>
        <div class="tipContent-button">
          <el-button type="primary" size="small">下载失败数据</el-button>
        </div>
      </div>
    </el-dialog> -->
    <!-- 添加发放 -->
    <x-dialog :proxy="publishOnlineDialog" :class="[dialogVisible ? 'publishOnlineDialogDisableOk' : '']"
      class="on-line-box">
      <el-tabs v-if="publishOnlineDialog.visible" v-model="createGrantType" type="card">
        <el-tab-pane label="发放给系统用户" name="1">
          <div class="row no-gutters align-items-center mb-3">
            <div class="col-auto">
              <el-select @change="changeUserList" v-model="createGrantParams.group_id" placeholder="请选择用户分组" clearable>
                <el-option v-for="item in UserOptions" :key="item.group_id" :label="item.group_name"
                  :value="item.group_id"></el-option>
              </el-select>
            </div>
            <div class="col-auto mr-3" style="margin-left: 12px;">
              <el-input placeholder="请输入手机号搜索" v-model="createGrantParams.keyword" class="input-with-select" clearable>
                <el-button @click="getMemberList" slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>

          </div>
          <div class="tables">
            <el-table class="multipleTable" ref="multipleTable" :row-key="row => row.member_id"
              @selection-change="createGrantTableSelectChange" :data="createGranList.data" border :header-cell-style="{
                textAlign: 'center',
                backgroundColor: 'rgba(230, 236, 247, 1)',
              }" :cell-style="headClass" style="width: 100%">
              <el-table-column :key="'slct'" type="selection" :reserve-selection="true" width="55"></el-table-column>
              <el-table-column :key="'nickname'" prop="nickname" label="用户姓名"></el-table-column>
              <el-table-column :key="'g_name'" prop="group_name" label="所属分组">
                <template slot-scope="scope">{{ scope.row.group_name ? scope.row.group_name : '——' }}</template>
              </el-table-column>
              <el-table-column :key="'mob'" prop="mobile" label="手机号"></el-table-column>
            </el-table>
          </div>
          <div style="text-align: center; margin-top: 20px" class="pages">
            <el-pagination @size-change="handlecreateGrantSizeChange" @current-change="handlecreateGrantCurrentChange"
              :current-page="createGranList.page_no" :page-sizes="MixinPageSizes" :page-size="createGranList.page_size"
              :layout="MixinTableLayout" :total="createGranList.data_total" background></el-pagination>
          </div>
          <div style="height:48px;display: flex;align-items: center;margin-top: 10px">
            <el-checkbox v-model="activationData.status" :true-label="1" :false-label="0"
              style="margin: 0">是否直接激活</el-checkbox>
            <el-form style="display: flex;" v-if="activationData.status" :model="activationData" :rules="activationForm"
              ref="activationRef" label-width="125px" class="demo-ruleForm">
              <el-form-item label="业务员名称" prop="active_salesman" style="width: 270px;display: inline-block;margin: 0">
                <el-input v-model="activationData.active_salesman" size="small"></el-input>
              </el-form-item>
              <el-form-item label="所属销售公司" prop="company" style="width: 270px;display: inline-block;margin: 0">
                <el-input v-model="activationData.company" size="small"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入发放名单" name="2">
          <div class="tipBox b2">
            <p style="margin:0">* 使用表格导入用户手机号发放名单，系统将自动给其发放优惠券。</p>
            <p style="margin:0">* 如果导入的手机号非当前商城用户，系统自动将当前手机保存到用户列表中。</p>
            <p style="margin:0 0 10px 0">* 导入的内容不可超过5000条数据；</p>
            <!-- <p>*姓名和分组可以为空，手机号不能为空</p> -->
          </div>
          <div class="shep">第一步：下载表格模板</div>
          <el-button style="margin-top: 10px; margin-bottom: 15px" type="default"
            @click="downTemplate1">下载表格模板</el-button>
          <div class="shep">第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式</div>
          <div class="shep">第三步：导入表格</div>
          <div class="add-camilo-file-box">
            <upload-excel-component defaultSize :on-success-tem="excelSuccess1"
              @upFileName="e => { this.tooltipContentTwo = e }" />
            <el-tooltip v-if="tooltipContentTwo" class="item" effect="dark" :content="tooltipContentTwo"
              placement="top-start">
              <el-button class="add-camilo-file">{{ tooltipContentTwo }}<i @click="clearnUploadFiles"
                  style="margin-left:6px" class="el-icon-circle-close"></i></el-button>
            </el-tooltip>
          </div>
          <div v-if="dialogVisible" style="margin-bottom: 10px;">
            <p>提示：当前文件中，存在无法正常导入的数据，请修改后重新上传文件</p>
            <p style="color: #007bff;cursor: pointer;" @click="downloadError(1)"><i class="el-icon-download"></i>{{
              `《${tooltipContentTwo.split('.x')[0]}》- 问题数据_` }}{{ Date.now() / 1000 | unixToDate('yyyyMMddhhmm') }}.xlsx
            </p>
          </div>
          <div style="height:68px;display: flex;align-items: center;margin: 10px 0">
            <el-checkbox v-model="activationData.status" :true-label="1" :false-label="0"
              style="margin: 0">是否直接激活</el-checkbox>
            <el-form style="display:flex" v-if="activationData.status" :model="activationData" :rules="activationForm"
              ref="activationRef" label-width="125px" class="demo-ruleForm">
              <el-form-item label="业务员名称" prop="active_salesman" style="width: 300px;display: inline-block;margin: 0">
                <el-input v-model="activationData.active_salesman" size="small"></el-input>
              </el-form-item>
              <el-form-item label="所属销售公司" prop="company" style="width: 300px;display: inline-block;margin: 0">
                <el-input v-model="activationData.company" size="small"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </x-dialog>

    <!-- 操作记录 -->
    <el-dialog title="操作记录" :visible.sync="recordFlag" width="80%" class="popinfo popinfo-box" append-to-body
      @close="closeRecord">
      <en-table-layout :tableData="recordTableData.data" :loading="loading" style="margin: -30px -20px">
        <template slot="toolbar">
          <el-form-item label="状态">
            <el-select size="medium" v-model="recordinform.oper_type" clearable
              style="width: 150px !important; margin-left: 10px">
              <el-option label="全部" value></el-option>
              <el-option label="激活" value="2"></el-option>
              <el-option label="禁用" value="1"></el-option>
              <el-option label="延期" value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input v-model="recordinform.key_word" size="medium" style="width: 220px" clearable
              placeholder="请输入兑换码进行搜索" />
          </el-form-item>

          <el-button size="small" type="primary" @click="serchRecordList">搜索</el-button>

          <div class="col"></div>

          <!-- <el-button :loading="exportAllIssueLoading" size="small" type="primary"
            @click="ExportGetrecordList2">导出全部</el-button> -->
        </template>
        <template slot="table-columns">
          <el-table-column :key="'serial_no'" label="编号">
            <template slot-scope="scope">{{
              scope.$index + 1 + (recordTableData.page_no - 1) * recordTableData.page_size
            }}</template>
          </el-table-column>
          <el-table-column :key="'opt_time'" prop="goods_name" label="操作时间">
            <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
          </el-table-column>
          <el-table-column :key="'oper_type'" prop="oper_type" label="操作类型">
            <template slot-scope="scope">
              {{ scope.row.oper_type === 1 ? '禁用' : scope.row.oper_type === 2 ? '激活' : scope.row.oper_type === 3 ? '延期' :
                '过期' }}
            </template>
          </el-table-column>
          <el-table-column :key="'coupon_code'" prop="coupon_code" label="券码id" />
          <el-table-column :key="'coupon_key'" prop="coupon_key" label="兑换码" />
          <el-table-column :key="'oper_name'" prop="oper_name" label="操作人" />
          <el-table-column :key="'ip'" prop="ip" label="ip地址" />
        </template>
        <el-pagination slot="pagination" v-if="recordTableData" @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1" :current-page="recordTableData.page_no" :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout" :page-size="recordTableData.page_size"
          layout="total, sizes, prev, pager, next, jumper" :total="recordTableData.data_total"></el-pagination>
      </en-table-layout>
    </el-dialog>

    <!-- 激活信息填写 -->
    <x-dialog :proxy="fillInActivationInformation">
      <el-form :model="jihuoFromData" :rules="jihuoFromDatarules" ref="ruleForm" label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="业务员名称" prop="active_salesman">
          <el-input v-model.trim="jihuoFromData.active_salesman"></el-input>
        </el-form-item>
        <el-form-item label="所属销售公司" prop="company">
          <el-input v-model.trim="jihuoFromData.company"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="660px">
      <p>要导入的表格中，{{ currentTab == '0' ? '存在不符合生成规则或重复数据或已存在于系统中的数据' : '存在非手机号或重复的数据' }}，请核对后重新上传。</p>
      <div class="errorContent">
        <div v-for="(item, index) in importErrorList" :key="index">
          <p style="margin-bottom: 0;">{{ item[0] }}：</p>
          <p>{{ item[1].toString() }}</p>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import * as API_HotGoods from "@/api/hotGoods";
import * as API_coupon from "@/api/coupon";
import UploadExcelComponent from "@/components/UploadExcel";
import {
  handleDownload,
  selfDefinedTemplate,
  couponSelfDefinedTemplate,
  couponSelfDefinedDown
} from "@/utils";
import { couponExport_down_to_excel } from '@/utils/vendor/Export2Excel'
import { UE } from "@/components";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "grant",
  components: {
    XDialog,
    EnTableLayout,
    [UE.name]: UE,
    UploadExcelComponent
  },
  props: {
    yfather: Number
  },
  data () {
    return {
      submitImportCardKeysFlag: false,
      changeRow: '',//选中的优惠券
      importErrorList: [],//导入错误信息集合
      dialogVisible: false, //导入错误提示弹窗
      tooltipContent: "",
      tooltipContentTwo: "",
      fillInActivationInformation: $xDialog.create({
        title: "激活信息填写",
        width: "30vw",
        beforeConfirm: () => this.handlejihuoFormData("ruleForm"),
        beforeCancel: () => this.resetForm("ruleForm")
      }),
      dialogRef: $xDialog.create({
        title: "优惠券管理",
        width: "95vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "cash-coupon-manager-main-dialog",
        afterDismiss: () => this.reset()
      }),
      viewCardDialogRef: $xDialog.create({
        title: "卡密详情",
        width: "95vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "view-card-dialog",
        afterDismiss: () => {
          this.tabChange(); // 刷新优惠券列表
          this.resetCarmiTableParams(); // 重置卡密列表参数
          this.CarmiTableList = { data: [] };
          this.CarmiParams = {
            coupon_id: null,
            page_size: 10,
            page_no: 1,
            total: 0
          };
        }
      }),
      currentTab: "0",
      loading: false,
      id: null,
      CarmiParams: {
        coupon_id: null,
        page_size: 10,
        page_no: 1,
        total: 0
      },
      CarmiTable: [],
      intervalType: 1,
      isCarmiTableList: false,
      CarmiTableParams: {
        coupon_manage_id: null,// 优惠券id
        status: "",//状态
        search_type: 'coupon_code',//查询类型
        search_value: "",//查询内容
        page_size: 20,
        page_no: 1,
        total: 0
      },
      status: "",
      promptText: "",
      dialogPromptText: "",
      CarmiTableList: { data: [] },
      CarmiTableListChange: [],
      // <!-- 1  已激活    2 未激活    3 已绑定    4 禁用中  -->
      CarmiTableListOptions: [],
      addCardPasswordDialog: $xDialog.create({
        title: "生成兑换码",
        width: "600px",
        destroyOnClose: true,
        wrapperClass: "addCardPasswordDialog",
        afterDismiss: () => {
          // 兑换码管理——生成兑换码弹窗数据
          this.createCarmiType = '1'
          this.tooltipContent = ''
          this.submitCreateCardKeysParams = {//系统生成兑换码
            coupon_id: "",
            num: ""
          },
            this.dialogVisible = false;
          this.submitImportCardKeysParams = {//表格导入兑换码
            coupon_id: "",
            coupon_name: "",
            shop_name: "",
            manage_coupon_key_list: [],
            type: ""
          },
            this.downLoadType = 0;//仅兑换码  券码ID+兑换码
        },
        beforeConfirm: () => {
          return new Promise(resolve => {
            if (this.createCarmiType === "1") {
              this.submitCreateCardKeys().then(() => resolve(true));
            } else {
              this.submitImportCardKeys();
            }
          });
        }
      }),
      postpone: $xDialog.create({
        title: "延期操作",
        width: "600px",
        wrapperClass: "addCardPasswordDialog",
        beforeConfirm: this.postponeFn,
        afterDismiss: () => this.postponeVal.num = ''
      }),
      postponeTip: $xDialog.create({
        title: "提示",
        width: "400px",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "addCardPasswordDialog",
      }),
      postponeTipText: "",
      uploadErrData: [],
      createCarmi: false,
      createCarmiType: "1",
      submitCreateCardKeysParams: {
        coupon_id: "",
        num: ""
      },
      submitCreateCardKeysRules: {
        num: [
          { required: true, message: "请输入生成数量", trigger: "blur" },
          {
            required: true,
            validator: (rules, value, callback) => {
              if (value > 5000) {
                callback(new Error("请输入1-5000的正整数"));
              } else if (value.toString().indexOf(".") !== -1) {
                callback(new Error("请输入1-5000的正整数"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      // 延期操作校验规则
      postponeRules: {
        num: [
          { required: true, message: '请输入延期天数', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 正整数校验规则
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error('延期天数只允许是正整数'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
      downLoadType: 0,
      submitImportCardKeysParams: {
        coupon_id: "",
        coupon_name: "",
        shop_name: "",
        manage_coupon_key_list: [],
        type: ""
      },

      grantList: {},
      grantListParams: {
        coupon_id: "",
        page_size: 10,
        page_no: 1,
        total: 0
      },
      publishOnlineDialog: $xDialog.create({
        title: "在线发放",
        width: "70vw",
        afterDismiss: () => {
          // 兑换码管理——生成兑换码弹窗数据
          this.createGrantType = '1'
          this.tooltipContentTwo = ''
          this.submitCreateGrantParams = {//发放系统用户表单内容
            coupon_id: '',//优惠券id
            binding_members: [],//绑定用户
            group_id: "", //用户组id
            group_name: '',//用户组名称
          },
            this.activationData = {//在线发放管理-发放给系统用户表单      
              status: 0,//是否直接激活 0 否  1是
              active_salesman: "",//业务员
              company: ""//所属销售公司
            },
            this.cardMemberVO = {//表格导入发放名单内容
              coupon_id: '',//优惠券id
              phone_list: {}
            }
          this.dialogVisible = false
        },
        beforeConfirm: () => {
          return new Promise(resolve => {
            if (this.createGrantType === "1") {//发放给系统用户
              this.submitCreateGrant("activationRef").then(() => resolve(true));
            } else {//表格导入发放名单
              this.submitCreateGrant1("activationRef")
            }
          });
        }
      }),
      createGrantType: "1",
      createGrantParams: {
        // shop_id: this.$store.getters.shopInfo.shop_id, // 店铺
        page_size: 20,
        page_no: 1,
        keyword: "",
        group_id: "" // 分组id
      },
      createGranList: {},
      createGranSelect: [],
      UserOptions: [],
      createGrantDielog: false,
      submitCreateGrantParams: {//发放系统用户表单内容
        coupon_id: '',//优惠券id
        binding_members: [],//绑定用户
        group_id: "", //用户组id
        group_name: '',//用户组名称
      },
      activationData: {//在线发放管理-发放给系统用户表单      
        status: 0,//是否直接激活 0 否  1是
        active_salesman: "",//业务员
        company: ""//所属销售公司
      },
      cardMemberVO: {//表格导入发放名单内容
        coupon_id: '',//优惠券id
        phone_list: {}
      },
      // 查看操作记录
      recordFlag: false,
      recordTableData: "",
      recordinform: {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: "",
        key_word: "",
      },
      // 记录是单个激活 还是激活全部
      jihuoFromDataAll: false,
      // 记录是否为激活选中
      jihuoFromDataRowVla: false,
      // 激活时  填写的表单数据
      jihuoFromDataFlag: false,
      jihuoFromData: {
        active_salesman: "", // 操作业务员
        company: "" // 所属销售公司
      },
      intervalFromData: {
        active_salesman: "", // 操作业务员
        company: "", // 所属销售公司
        cardList: [
          {
            card_code_start: "",
            card_code_end: "",
            dialogPromptText: ""
          }
        ]
      },
      jihuoFromRow: "",
      jihuoFromnum: "",
      jihuoFromDatarules: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur"
          },
          { max: 64, message: "长度不能超过 64 个字符" }
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur"
          },
          { max: 64, message: "长度不能超过 64 个字符" }
        ]
      },
      activationForm: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur"
          },
          { max: 32, message: "长度不能超过 32 个字符" }
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur"
          },
          { max: 32, message: "长度不能超过 32 个字符" }
        ]
      },
      intervalFromDatarules: {
        active_salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur"
          },
          { max: 32, message: "长度不能超过 32 个字符" }
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur"
          },
          { max: 32, message: "长度不能超过 32 个字符" }
        ]
      },
      falg: 1, // 记录是点击的卡密还是发放   1 卡密   2  发放
      rowData: {}, // 记录行数据
      coupon_key: "", //搜索框数据
      exportAllLoading: false,
      exportAllIssueLoading: false,
      cancelActivatedAllLoading: false,
      showLink: false,
      postponeVal: {
        num: ""
      },// 延期天数
    };
  },
  watch: {
    currentTab: {
      handler (newValue) {
        if (newValue === "1") {
          this.CarmiTableListOptions = [
            { value: "", label: "全部" },
            { value: "0", label: "未激活" },
            // { value: "1", label: "已激活" },
            { value: "3", label: "已领取" },
            { value: "4", label: "已使用" },
            { value: "6", label: "已禁用" },
            { value: "7", label: "已过期" },
          ];
        } else {
          this.CarmiTableListOptions = [
            { value: "", label: "全部" },
            { value: "0", label: "未激活" },
            { value: "1", label: "已激活" },
            { value: "3", label: "已领取" },
            { value: "4", label: "已使用" },
            { value: "6", label: "已禁用" },
            { value: "7", label: "已过期" },
          ];
        }
      },
      immediate: true
    }
  },
  created () {
    this.MixinSetShopExtAuthList().then(res => {
      if (res.toHiddenAuth.includes("shareVideoList")) {
        this.showLink = false;
      } else {
        this.showLink = true;
      }
    });
  },
  mounted () {
    if (this.yfather) {
      this.id = this.yfather;
      this.btnType = 0;
    }
    this.getUserlist();//查询用户分组集合
  },
  methods: {
    addCardPassword () {
      this.addCardPasswordDialog.display();
    },
    show (row) {
      this.changeRow = row;
      this.id = row.id;
      this.dialogRef.display({
        title: `优惠券管理——${row.coupon_name}`
      });
      this.tabChange();
    },
    //重置内容
    reset () {
      this.currentTab = "0";
      this.CarmiParams = {//兑换码管理列表查询参数
        coupon_id: null,
        page_size: 10,
        page_no: 1,
        total: 0
      };
      this.CarmiTable = [];

      this.grantList = {};
      this.grantListParams = {//在线发放列表查询参数
        coupon_id: "",
        page_size: 10,
        page_no: 1,
        total: 0
      };

    },
    tabChange () {//兑换码管理/在线发放 页签切换事件
      this.coupon_key = '';//页签切换清空搜索内容
      switch (this.currentTab) {
        case "0":
          // 兑换码管理列表
          return this.getCardLogList();
        case "1":
          // 在线发放列表
          return this.getProvideLog();
      }
    },
    // 搜索按钮
    handleClickSousuo () {
      if (this.currentTab === "0") {
        this.CarmiParams.page_no = 1;
        this.CarmiParams.page_size = 10;
        this.getCardLogList(); // 兑换码管理列表
      } else {
        this.grantListParams.page_no = 1;
        this.grantListParams.page_size = 10;
        this.getProvideLog(); // 在线发放列表
      }
    },
    handleClose () {
      this.isCarmiTableList = false;
    },
    handleClickXinjian () {//打开新建发放弹窗
      this.activationData.status = 0;//重置激活状态
      this.activationData.active_salesman = "";//重置业余员
      this.activationData.company = "";//重置所属销售公司
      this.createGrantType = '1';//重置新建发放弹窗页签选中  默认发放给系统用户
      this.submitCreateGrantParams = {//发放给系统用户表单内容
        coupon_id: '',//优惠券id
        binding_members: [],//绑定用户
        group_id: "", //用户组id
        group_name: '',//用户组名称
      },
        this.getMemberList().then(() => {//查询用户列表
          this.publishOnlineDialog.display();//打开新建发放弹窗
        });
    },
    headClass () {
      //表头居中显示
      return "text-align:center";
    },
    // 导出全部
    async ExportGetrecordList1 () {
      let unixToDate = this.$root.$options.filters.unixToDate
      this.exportAllLoading = true
      const state = (item) => {
        return +item.exp_status ? '已过期' :
          item.status === 1
            ? '已激活'
            : item.status === 3
              ? '已领取'
              : item.status === 4
                ? '已使用'
                : item.status === 6
                  ? '已禁用' : item.status === 7
                    ? '已过期'
                    : '未激活'
      }
      const getTime = (item) => {
        return unixToDate(item.start_time, 'yyyy-MM-dd hh:mm:ss') + '至' + unixToDate(item.end_time, 'yyyy-MM-dd hh:mm:ss')
      }
      const obj = {
        coupon_manage_id: this.rowData.id,
        coupon_id: this.rowData.coupon_id,
        page_no: 0,
        page_size: 0
      };
      let CarmiTableParams = {
        ...this.CarmiTableParams
      }
      //如果有模糊查询内容就查找
      CarmiTableParams.search_value.trim() ? CarmiTableParams[CarmiTableParams.search_type] = CarmiTableParams.search_value.trim() : ''
      CarmiTableParams.search_type = null
      CarmiTableParams.search_value = null
      try {
        const { data } = await API_coupon.getCouponKeyList(Object.assign(CarmiTableParams, obj))
        // 下载全部
        const downloadAll = []
        data.data.forEach((item, index) => {
          downloadAll.push(
            [
              index + 1,
              item.coupon_code, // id
              item.coupon_key,  // 兑换码
              item.binding_member_mobile, // 领用用户手机号
              unixToDate(item.binding_time, 'yyyy-MM-dd hh:mm:ss'), // 领取时间
              unixToDate(item.use_time, 'yyyy-MM-dd hh:mm:ss'), // 使用时间
              getTime(item), // 兑换码有效期
              state(item), // 状态
              item.company, // 所属销售公司
              item.active_salesman] // 业务员
          )
        })
        couponExport_down_to_excel(
          ['编号', "券码ID", '兑换码', '领用用户手机号', '领取时间', '使用时间', '兑换码有效期', '状态', '所属销售公司', '业务员']
          , downloadAll,
          "兑换码列表_" + +new Date())
        this.exportAllLoading = false;
      } catch (error) {
        this.exportAllLoading = false;

      }

    },
    resetCarmiTableParams () {
      this.CarmiTableParams = {
        coupon_manage_id: null,// 优惠券id
        status: "",//状态
        search_type: 'coupon_code',//查询类型
        search_value: "",//查询内容
        page_size: 20,
        page_no: 1,
        total: 0
      }
    },
    // 操作记录导出全部
    ExportGetrecordList2 () {
      this.exportAllIssueLoading = true;
      let obj = {};
      obj.card_id = this.CarmiTableParams.card_id;
      obj.log_id = this.CarmiTableParams.log_id;
      obj.provide_log_id = this.CarmiTableParams.provide_log_id;
      obj.page_no = 0;
      obj.page_size = 0;

      API_coupon.getCardsKeyOperationLog(obj)
        .then(res => {
          this.exportAllIssueLoading = false;
          const json = {
            sheet_name: "操作日志",
            sheet_values: res.data.map((item, idx) => ({
              编号: idx + 1,
              操作时间: Foundation.unixToDate(item.create_time),
              操作类型: item.oper_type === 1 ? "禁用" : "激活",
              卡号: item.card_code,
              卡密: item.card_key,
              操作人: item.opr_name,
              ip地址: item.ip
            }))
          };
          this.MixinExportJsonToExcel(json, "操作日志");
        })
        .catch(_ => {
          this.exportAllIssueLoading = false;
        });
    },
    // 打开操作记录弹窗
    handleClickRecord () {
      this.recordFlag = true;
      this.getrecordList();
    },
    // 搜索操作记录
    serchRecordList () {
      this.recordinform.page_no = 1;
      this.getrecordList();
    },
    // 关闭操作记录弹窗
    closeRecord () {
      this.recordFlag = false;
      this.recordinform = {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: "",
        key_word: "",
      }
    },
    //查询操作记录
    getrecordList () {
      this.loading = true;
      let obj = {};
      obj.coupon_manage_id = this.rowData.id;
      obj.coupon_id = this.rowData.coupon_id;
      obj.page_no = this.recordinform.page_no;
      obj.page_size = this.recordinform.page_size;
      obj.oper_type = this.recordinform.oper_type;
      obj.coupon_key = this.recordinform.key_word;
      API_coupon.getCouponKeyLog(obj)
        .then(({ data: res }) => {
          this.loading = false;
          this.recordTableData = res;
          this.recordinform.total = res.data_total;
          this.recordinform.page_no = res.page_no;
          this.recordinform.page_size = res.page_size;
        })
        .catch(_ => {
          this.loading = false;
        });
    },
    // 操作记录分页
    handlePageSizeChange1 (val) {
      this.recordinform.page_size = val;
      this.getrecordList();
    },
    // 操作记录分页
    handlePageCurrentChange1 (val) {
      this.recordinform.page_no = val;
      this.getrecordList();
    },
    getCardLogList () {
      // 兑换码管理列表
      this.CarmiParams.coupon_id = this.id;
      this.CarmiParams.coupon_key = this.coupon_key;
      this.CarmiParams.manage_type = 1;
      API_coupon.getCoupons(this.CarmiParams).then(res => {
        this.CarmiTable = res.data.data;
        this.CarmiParams.page_no = res.data.page_no;
        this.CarmiParams.page_size = res.data.page_size;
        this.CarmiParams.total = res.data.data_total;
      });
    },
    handleCarmiTableSizeChange (val) {
      this.CarmiParams.page_size = val;
      this.getCardLogList();
    },
    handleCarmiTableCurrentChange (val) {
      this.CarmiParams.page_no = val;
      this.getCardLogList();
    },
    handleCarmiTableDetailrow (row) {
      this.rowData = row;
      this.getLookList();
    },
    // 查看
    async getLookList (search = false) {
      this.CarmiTableParams.coupon_manage_id = this.rowData.id;
      search ? this.CarmiTableParams.page_no = 1 : ''
      let CarmiTableParams = {
        ...this.CarmiTableParams
      }
      //如果有模糊查询内容就查找
      CarmiTableParams.search_value ? CarmiTableParams[CarmiTableParams.search_type] = CarmiTableParams.search_value.trim() : ''
      delete CarmiTableParams.search_type
      delete CarmiTableParams.search_value
      const { data } = await API_coupon.getCouponKeyList(CarmiTableParams);
      this.CarmiTableList = data;
      this.viewCardDialogRef.display();
      this.$nextTick(() => {
        this.$refs.viewCardTable.clearSelected();
      });
    },
    handleCarmiTableListSizeChange (val) {
      this.CarmiTableParams.page_size = val; // 赋值
      this.getLookList(); // 查看
    },
    handleCarmiTableListCurrentChange (val) {
      this.CarmiTableParams.page_no = val;
      this.getLookList();
    },

    resetForm (formName) {
      this.jihuoFromDataFlag = false;
      if (formName === 'ruleForm') this.resetFields() // 重置激活信息填写表单
      else this.$refs[formName]?.resetFields()
    },
    //  重置表单
    resetFields () {
      this.jihuoFromData = {
        active_salesman: "", // 操作业务员
        company: "" // 所属销售公司
      }
    },
    // 激活表单确定
    handlejihuoFormData (formName) {
      if (this.jihuoFromDataAll) {
        // 操作全部
        API_coupon.putOperateCoupons({
          coupon_manage_id: this.rowData.id, // 优惠券管理id
          coupon_id: this.rowData.coupon_id, // 优惠券id
          // card_id: this.id,
          type: 2, // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
          // 公司名字
          company: this.jihuoFromData.company,
          // 业务员
          active_salesman: this.jihuoFromData.active_salesman
        }).then(res => {
          // this.selectCarmiTable = [];
          this.$message({
            type: "success",
            message: "全部激活成功"
          });
          this.getLookList(); // 查看
          this.jihuoFromDataFlag = false; // 关闭弹窗
          if (formName === 'ruleForm') this.resetFields() // 重置激活信息填写表单
          else this.$refs[formName]?.resetFields()
        });
      } else {
        // 激活的是选中的数据
        this.$refs[formName].validate(valid => {
          if (valid) {
            // 是否为激活选中
            if (this.jihuoFromDataRowVla) {
              let arr2 = [];
              this.selectCarmiTable.map(item => {
                arr2.push(item.id);
              });
              API_coupon.putOperateCoupons({
                coupon_manage_id: this.rowData.id, // 优惠券管理id
                coupon_id: this.rowData.coupon_id, // 优惠券id
                operate_ids: arr2, // 操作集合ids（激活、禁用时使用）
                type: 0, // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
                active_salesman: this.jihuoFromData.active_salesman, // 操作业务员
                company: this.jihuoFromData.company // 所属销售公司
              }).then(res => {
                // this.selectCarmiTable = [];
                this.$message({
                  type: "success",
                  message: "选中项激活成功"
                });
                this.getLookList(); // 刷新列表
                this.jihuoFromDataFlag = false; // 关闭弹窗
                if (formName === 'ruleForm') this.resetFields() // 重置激活信息填写表单
                else this.$refs[formName]?.resetFields()
              });
            } else {
              // 激活当前
              let arr = [];
              arr.push(this.jihuoFromRow.id);
              // jihuoFromData.active_salesman  company
              API_coupon.putOperateCoupons({
                coupon_manage_id: this.rowData.id, // 优惠券管理id
                coupon_id: this.jihuoFromRow.coupon_id, // 优惠券id
                operate_ids: arr, // 操作集合ids（激活、禁用时使用）
                // card_id: this.id,
                type: 1, // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
                active_salesman: this.jihuoFromData.active_salesman, // 操作业务员
                company: this.jihuoFromData.company // 所属销售公司
              }).then(res => {
                this.$message({
                  type: "success",
                  message: "激活成功"
                });
                this.getLookList(); // 刷新列表
                this.jihuoFromDataFlag = false; // 关闭弹窗
                if (formName === 'ruleForm') this.resetFields() // 重置激活信息填写表单
                else this.$refs[formName]?.resetFields()
              });
            }
          } else {
            return false;
          }
        });
      }
    },
    handleCarmiTableListActivation (row, num) {
      if (num === "postpone")
        return (this.jihuoFromRow = row), this.postpone.display(); // 延期
      if (num === 0 || num === 6) {
        // 激活操作
        this.jihuoFromRow = row;
        this.jihuoFromnum = num;
        this.jihuoFromData.active_salesman = row.active_salesman; // 操作业务员
        this.jihuoFromData.company = row.company; // 所属销售公司
        this.fillInActivationInformation.display();
        this.jihuoFromDataAll = false;
      } else {
        this.$confirm(`确定要禁用该优惠券吗?`, "提示", { type: "warning" })
          .then(() => {
            // 禁用当前
            let arr = [];
            arr.push(row.id);
            // jihuoFromData.active_salesman  company
            API_coupon.putOperateCoupons({
              coupon_manage_id: this.rowData.id, // 优惠券管理id
              coupon_id: row.coupon_id, // 优惠券id
              operate_ids: arr, // 操作集合ids（激活、禁用时使用）
              // card_id: this.id,
              type: 4 // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
            }).then(res => {
              this.$message({
                type: "success",
                message: "禁用成功"
              });
              this.getLookList(); // 刷新列表
            });
          })
          .catch(() => { });
      }
    },
    CarmiTableListSelectChange (val) {
      this.selectCarmiTable = val;
    },
    handleActivationCheck (num) {
      // 批量激活
      let arr = [];
      if (
        this.selectCarmiTable &&
        this.selectCarmiTable.length &&
        this.selectCarmiTable.length > 0
      ) {
        this.selectCarmiTable.map(item => {
          arr.push(item.id);
        });
      } else {
        this.$message.error("请先选择要激活的兑换码");
        return;
      }
      this.jihuoFromDataRowVla = false; // 记录是否为激活选中数据
      if (num === 0) {
        // 激活需要填写业务员
        this.fillInActivationInformation.display();
        // 点击的不是操作全部
        this.jihuoFromDataAll = false;
        // 记录是否为激活选中数据
        this.jihuoFromDataRowVla = true;
      } else {
        this.$confirm(`确定要禁用当前的兑换码吗？`, "提示", { type: "warning" })
          .then(async () => {
            let arr2 = [];
            this.selectCarmiTable.map(item => {
              arr2.push(item.id);
            });
            API_coupon.putOperateCoupons({
              coupon_manage_id: this.rowData.id, // 优惠券管理id
              coupon_id: this.rowData.coupon_id, // 优惠券id
              operate_ids: arr2, // 操作集合ids（激活、禁用时使用）
              // card_id: this.id,
              type: 3 // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
            }).then(res => {
              // this.selectCarmiTable = [];
              this.$message({
                type: "success",
                message: num === 0 ? "选中项激活成功" : "选中项禁用成功"
              });
              this.getLookList(); // 刷新列表
              this.jihuoFromDataFlag = false; // 关闭弹窗
              this.$refs[formName].resetFields(); // 重置表单
            });
          })
          .catch(() => { });
      }
    },
    handleActivationAll (num) {
      // 记录点的是激活全部按钮
      this.jihuoFromDataAll = true;

      if (num === 0) {
        // 激活需要填写业务员
        this.fillInActivationInformation.display();
      } else {
        this.$confirm(`确定要禁用当前的兑换码吗？`, "提示", { type: "warning" })
          .then(async () => {
            // 操作全部
            this.cancelActivatedAllLoading = true;
            API_coupon.putOperateCoupons({
              coupon_manage_id: this.rowData.id, // 优惠券管理id
              coupon_id: this.rowData.coupon_id, // 优惠券id
              type: 5 // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
            })
              .then(res => {
                // this.selectCarmiTable = [];
                this.cancelActivatedAllLoading = false;
                this.$message({
                  type: "success",
                  message: num === 0 ? "全部激活成功" : "全部禁用成功"
                });
                this.getLookList(); // 查看
                this.jihuoFromDataFlag = false; // 关闭弹窗
                this.$refs[formName].resetFields(); // 重置表单
              })
              .catch(() => {
                this.cancelActivatedAllLoading = false;
              });
          })
          .catch(() => { });
      }
    },
    // 延期操作
    postponeFn () {
      let flag = true
      this.$refs.postpone.validate(valid => {
        if (valid) {
          API_coupon.putOperateCoupons({
            coupon_manage_id: this.rowData.id, // 优惠券管理id
            coupon_id: this.rowData.coupon_id, // 优惠券id
            type: 6, // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
            adjourn_list: [
              {
                coupon_code: this.jihuoFromRow.coupon_code, // 优惠券码
                days: this.postponeVal.num + '' // 延期天数
              }
            ]
          })
            .then(res => {
              this.postponeLoading = false;
              const { data: { err_data, success_data } } = res;
              if (err_data.length) {
                this.$message.error('延期失败')
              } else {
                this.$message({
                  type: "success",
                  message: "延期成功"
                });
              }
              this.getLookList(); // 查看
              this.$refs["postpone"].resetFields(); // 重置表单
            })
            .catch(() => {
              this.postponeLoading = false;
            });
        } else {
          return flag = false;
        }
      });
      return flag
    },
    //系统生成兑换码
    submitCreateCardKeys () {
      this.submitCreateCardKeysParams.coupon_id = this.id;
      if (this.dialogVisible || this.submitImportCardKeysFlag) return
      this.submitImportCardKeysFlag = true
      return API_coupon.systemAdd(this.submitCreateCardKeysParams).then(res => {
        this.submitImportCardKeysFlag = false
        this.$message({
          type: "success",
          message: "兑换码生成成功"
        });
        this.createCarmi = false;
        this.getCardLogList();
      });
    },
    downTemplate () {
      // 下载模板
      let tHeaders = [],
        filterVals = [];
      if (this.downLoadType === 0) {
        tHeaders = ["兑换码"];
        filterVals = ["yyyy"];
      }

      this.MixinSetShopExtAuthList().then(res => {
        if (this.downLoadType === 1) {
          //仅兑换码
          if (res.toHiddenAuth.includes("shareVideoList")) {
            tHeaders = ["券码ID", "兑换码"];
          } else {
            tHeaders = ["券码ID", "兑换码", "卡号前缀"];
          }
          filterVals = ["xxxx", "yyyy"];
        }
        couponSelfDefinedTemplate(
          this.integralImportData,
          tHeaders,
          filterVals,
          "兑换码导入模板"
        );
      });
    },
    /** 下载延期数据模板 */
    downDataTemplate () {

      couponExport_down_to_excel([
        "券码ID", '延期天数'
      ], [[], []],
        "延期数据模板")

    },
    /** 商城延期天数模板 */
    uploadExcelSuccess ({ results, shop_name, card_name }) {
      if (!results[0] || !results[0]["券码ID"] || !results[0]["延期天数"]) return this.$message.error("文件格式不正确，请重新上传")
      this.cancelActivatedAllLoading = true;
      API_coupon.putOperateCoupons({
        coupon_manage_id: this.rowData.id, // 优惠券管理id
        coupon_id: this.rowData.coupon_id, // 优惠券id
        adjourn_list: results.map(item => {
          return {
            coupon_code: item['券码ID']?.toString().trim() ?? '', // 优惠券码
            days: item['延期天数']?.toString().trim() ?? '' // 延期天数
          };
        }),
        type: 7 // 操作类型：0：激活选中 1：激活 2：激活全部 3：禁用选中 4：禁用 5：禁用全部 6：延期 7：导入延期数据
      })
        .then(res => {
          // debugger
          this.postponeTipText = `本次表格导入数据共${results.length}条，其中成功${res.data.success_data.length}条，失败${res.data.err_data.length}条。`
          // this.postponeTip = true
          this.uploadErrData = res.data.err_data // 失败数据暂存
          this.postponeTip.display()
          console.log('tip', this.postponeTipText);
          this.getLookList(); // 更新数据
          this.cancelActivatedAllLoading = false;
        })
        .catch(() => {
          this.cancelActivatedAllLoading = false;
        });
    },
    /** 下载失败数据 */
    downloadErrData () {
      console.log(this.uploadErrData)
      if (!this.uploadErrData.length) return this.$message.error('没有失败数据')
      const errData = []
      this.uploadErrData.forEach(item => {
        errData.push([item.coupon_code, item.days, item.error_msg])
      })
      couponExport_down_to_excel([
        "券码ID", '延期天数', '失败原因'
      ], errData,
        "延期失败数据")
      this.postponeTip.dismiss()// 关闭弹窗
    },
    //删除已上传文件
    clearnUploadFiles () {
      this.dialogVisible = false;
      this.importErrorList = [];
      //删除兑换码管理的已上传文件
      this.tooltipContent = '';
      this.submitImportCardKeysParams.manage_coupon_key_list = [];
      this.submitImportCardKeysParams.shop_name = ''; //商城名称
      this.submitImportCardKeysParams.coupon_name = ''; //优惠券名称
      //删除在线发放的已上传文件
      this.tooltipContentTwo = ''
      this.cardMemberVO.phone_list = [];//手机号
      this.cardMemberVO.shop_name = '';//商城名称
      this.cardMemberVO.coupon_name = '';//优惠券名称
    },
    //下载兑换码/在线发放导入上传失败的文件
    downloadError (flag = 0) {
      let unixToDate = this.$root.$options.filters.unixToDate;
      const errData = []
      // debugger
      if (flag) {//在线发放
        const fileName = `《${this.tooltipContentTwo.split('.x')[0]}》- 问题数据_` + unixToDate(Date.now() / 1000, 'yyyyMMddhhmm');
        this.importErrorList.forEach(item => {
          errData.push([item.phone, item.error_msg])
        })
        couponExport_down_to_excel([
          "手机号", '错误原因'
        ], errData,
          fileName)
      } else {//兑换码管理
        const fileName = `《${this.tooltipContent.split('.x')[0]}》- 问题数据_` + unixToDate(Date.now() / 1000, 'yyyyMMddhhmm');
        if (this.downLoadType == 0) {//仅兑换码
          this.importErrorList.forEach(item => {
            errData.push([item.coupon_key, item.error_msg])
          })
          couponExport_down_to_excel([
            "兑换码", '错误原因'
          ], errData,
            fileName)
        } else {//劵码id+兑换码
          this.importErrorList.forEach(item => {
            errData.push([item.coupon_code, item.coupon_key, item.error_msg])
          })
          couponExport_down_to_excel([
            "券码ID", '兑换码', '错误原因'
          ], errData,
            fileName)
        }
      }
    },
    //表格导入兑换码或劵码id+兑换码上传成功回调
    async excelSuccess ({ results, shop_name, card_name }) {
      if (this.changeRow.shop_name != shop_name) {
        this.tooltipContent = '';
        this.$alert('表格中商城名称和当前商城名称不符', '提示').then(() => { }).catch(() => { })
        return
      } else if (this.changeRow.coupon_name != card_name) {
        this.tooltipContent = '';
        this.$alert('表格中优惠券名称和当前优惠券名称不符', '提示').then(() => { }).catch(() => { })
        return
      } else if (results.length > 5000) {
        this.tooltipContent = '';
        this.$alert('要导入的表格数据量超过5000条的上限，请重新选择文件上传', '提示').then(() => { }).catch(() => { })
        return
      }
      this.dialogVisible = false;
      this.submitImportCardKeysParams.manage_coupon_key_list = [];
      this.submitImportCardKeysParams.shop_name = shop_name.trim(); //商城名称
      this.submitImportCardKeysParams.coupon_name = card_name.trim(); //优惠券名称
      if (this.downLoadType === 0) {
        if (!results[0] || !results[0]["兑换码"]) return this.$message.error("文件格式不正确，请重新上传"), this.tooltipContent = ''
        if (Object.keys(results[0]).length != 1) return this.$message.error("模板类型不正确，请重新上传"), this.tooltipContent = ''
        results.map((item, index) => {
          //仅兑换码
          this.submitImportCardKeysParams.manage_coupon_key_list.push({
            coupon_key: item["兑换码"].toString().trim()
          });
        });
      } else {
        if (!results[0] || !results[0]["券码ID"] || !results[0]["兑换码"]) return this.$message.error("文件格式不正确，请重新上传"), this.tooltipContent = ''
        if (Object.keys(results[0]).length != 2) return this.$message.error("模板类型不正确，请重新上传"), this.tooltipContent = ''
        results.map((item, index) => {
          //券码ID + 兑换码
          this.submitImportCardKeysParams.manage_coupon_key_list.push({
            coupon_code: item["券码ID"] ? item["券码ID"].toString().trim() : '',
            coupon_key: item["兑换码"] ? item["兑换码"].toString().trim() : ''
          });
        })
      }
    },
    //数组中根据对象唯一标识对象去重
    //参数1: 是一个数组,数组中是对象
    //参数2: 每一个对象中的唯一标识
    uniqueArrObj (arrhas, unique) {
      let arr = [];
      let arred = [];
      let data = this.MixinClone(arrhas);
      data.map(item => {
        arr.push(item[unique]);
        arred.push(item[unique]);
        if (unique === "card_code") {
          if (item[unique].length < 6 || item[unique].length > 16) {
            return false;
          }
        }
        if (unique === "card_key") {
          if (item[unique].length < 8 || item[unique].length > 20) {
            return false;
          }
        }
      });
      arr = [...new Set(arr)];
      return arr.length === arred.length;
    },
    //兑换码管理表格导入兑换码
    submitImportCardKeys () {
      if (this.dialogVisible || this.submitImportCardKeysFlag) return
      this.submitImportCardKeysFlag = true
      if (this.tooltipContent == '') return this.$message.error('请上传文件')
      if (
        !this.submitImportCardKeysParams.manage_coupon_key_list[0] ||
        (this.downLoadType &&
          !this.submitImportCardKeysParams.manage_coupon_key_list[0]
            .coupon_code) ||
        (!this.downLoadType &&
          !this.submitImportCardKeysParams.manage_coupon_key_list[0].coupon_key)
      ) {
        this.$message.error("导入的数据模板有误，请重新导入");
        return;
      }
      // 导入表格生成兑换码
      this.submitImportCardKeysParams.coupon_id = this.id;
      this.submitImportCardKeysParams.type = this.downLoadType === 0 ? 1 : 2; //1:仅兑换码 2:券码ID+兑换码
      return new Promise((resolve, reject) => {
        API_coupon.tableAdd(this.submitImportCardKeysParams)
          .then(res => {
            this.submitImportCardKeysFlag = false
            if (res.code == -1) {
              //上传错误
              this.dialogVisible = true;
              this.importErrorList = res.data;
            } else {
              //上传成功
              this.addCardPasswordDialog.dismiss() //关闭弹窗
              this.getCardLogList();
              this.$message.success('导入成功')
            }
            // let alert_content = `本次表格导入数据共${res.false +
            //   res.success}条, 其中成功${res.success}条, 失败${res.false}条`;
            // if (res.false > 0) {
            //   this.$alert(alert_content, "提示", {
            //     confirmButtonText: "下载失败数据"
            //   })
            //     .then(() => {
            //       window.location.href = res.file;
            //     })
            //     .catch(() => {});
            // } else {
            //   this.$alert(alert_content, "提示")
            //     .then(() => {})
            //     .catch(() => {});
            // }

          }).catch(() => {
            resolve(false)
          })
      })
    },
    // 在线发放列表
    getProvideLog () {
      this.grantListParams.coupon_id = this.id;
      this.grantListParams.coupon_key = this.coupon_key;
      this.grantListParams.manage_type = 2;
      API_coupon.getCoupons(this.grantListParams).then(res => {
        // this.grantListParams.page_no = res.data_page_no;
        // this.grantListParams.page_size = res.data_page_size;
        // this.grantListParams.total = res.data_total;
        this.grantList = res.data;
      });
    },
    handlegrantListSizeChange (val) {
      this.grantListParams.page_size = val;
      this.getProvideLog();
    },
    handlegrantListCurrentChange (val) {
      this.grantListParams.page_no = val;
      this.getProvideLog();
    },
    getMemberList () {//查询系统分组用户
      let obj = this.MixinClone(this.createGrantParams);
      if (!obj.group_id) {
        obj.group_id = 0;
      }

      return API_HotGoods.getMemberList(obj).then(res => {
        // this.createGrantParams.page_no = res.page_no;
        // this.createGrantParams.page_size = res.page_size;
        // this.createGrantParams.total = res.data_total;
        this.createGranList = res;
      });
    },
    handlecreateGrantSizeChange (val) {
      this.createGrantParams.page_size = val;
      this.getMemberList();
    },
    handlecreateGrantCurrentChange (val) {
      this.createGrantParams.page_no = val;
      this.getMemberList();
    },
    //新建发放弹窗——发放给系统用户——复选框事件
    createGrantTableSelectChange (val) {
      this.createGranSelect = JSON.parse(JSON.stringify(val));
    },
    getUserlist () {//查询用户分组集合
      let info = {
        page_size: 0,
        page_no: 0
      };
      API_HotGoods.getUserlist(info).then(res => {
        this.UserOptions = res.data;
      });
    },
    changeUserList (e) {//用户分组change事件
      if (!e) {
        this.submitCreateGrantParams.group_id = 0;
        this.submitCreateGrantParams.group_name = '';
      } else {
        const groupData = this.UserOptions.find(item => item.group_id == e)
        this.submitCreateGrantParams.group_name = groupData.group_name;
        this.submitCreateGrantParams.group_id = e;
      }
      this.$refs.multipleTable.clearSelection(); // 每次变换清空一次数据
      this.createGrantParams.page_no = 1;
      this.getMemberList();
    },
    submitCreateGrant (formName) {//在线发放——新建发放——提交发放给系统用户
      let status = true;
      if (this.activationData.status) {
        this.$refs[formName].validate(valid => {
          status = valid;
        });
      }
      // 发放给系统用户提交
      if (this.createGranSelect.length === 0) {
        this.$message.error("请先选择用户");
        return;
      }
      if (status) {//验证必填项
        //防止重复提交——开始
        if (this.dialogVisible || this.submitImportCardKeysFlag) return
        this.submitImportCardKeysFlag = true
        //结束
        this.submitCreateGrantParams.coupon_id = this.id;
        this.submitCreateGrantParams.binding_members = [];//绑定用户
        // 是否激活
        if (this.activationData.status) {
          // 激活
          this.submitCreateGrantParams.status = 1;
          this.submitCreateGrantParams.active_salesman = this.activationData.active_salesman;//业务员
          this.submitCreateGrantParams.company = this.activationData.company;//所属公司
        } else if (this.submitCreateGrantParams.active_salesman) {
          // 没激活删除对应参数
          delete this.submitCreateGrantParams.active_salesman;
          delete this.submitCreateGrantParams.company;
          this.submitCreateGrantParams.status = 0;
        }
        this.createGranSelect.forEach(item => {//添加绑定用户
          let obj = {
            binding_member_id: item.member_id,
            binding_member_mobile: item.mobile,
            binding_member_name: item.nickname
          }
          this.submitCreateGrantParams.binding_members.push(obj)
        });

        if (!this.submitCreateGrantParams.group_id) {//判断用户分组如果为空
          this.submitCreateGrantParams.group_id = this.createGranSelect[0].group_id ? this.createGranSelect[0].group_id : 0;
        }
        return API_coupon.grantSystemAdd(this.submitCreateGrantParams).then(
          res => {
            this.submitImportCardKeysFlag = false
            this.$message({
              title: "提示",
              message: "成功发放",
              type: "success"
            });
            this.createGrantDielog = false;
            this.$refs.multipleTable.clearSelection();
            this.getProvideLog();
          }
        );
      } else this.$message.error("业务员名称和所属销售公司不能为空");
    },
    downTemplate1 () {// 下载模板进行编辑   
      let tHeaders = ["手机号"];
      couponSelfDefinedTemplate(this.integralImportData, tHeaders);
    },
    excelSuccess1 ({ results, shop_name, card_name }) {//在线发放模板上传成功的回调函数

      if (this.changeRow.shop_name != shop_name) {
        this.tooltipContentTwo = '';
        this.$alert('表格中商城名称和当前商城名称不符', '提示').then(() => { }).catch(() => { })
        return
      } else if (this.changeRow.coupon_name != card_name) {
        this.tooltipContentTwo = '';
        this.$alert('表格中优惠券名称和当前优惠券名称不符', '提示').then(() => { }).catch(() => { })
        return
      } else if (results.length > 5000) {
        this.tooltipContentTwo = '';
        this.$alert('要导入的表格数据量超过5000条的上限，请重新选择文件上传', '提示').then(() => { }).catch(() => { })
        return
      } else if (!results[0] || !results[0]["手机号"]) {
        this.tooltipContentTwo = '';
        this.$message.error("文件格式不正确，请重新上传")
        return
      }
      this.dialogVisible = false;
      let resultsList = [];
      results.forEach((list, idx) => {
        // if (!RegExp.mobile.test(list.mobile)){
        // 	list.errorText = '手机号格式非法'
        // 	this.isError = true
        // }
        resultsList.push(list['手机号'].toString().trim());
      });
      this.cardMemberVO.coupon_id = this.id;//优惠券id
      this.cardMemberVO.shop_name = shop_name.trim();//商城名称
      this.cardMemberVO.coupon_name = card_name.trim();//优惠券名称
      this.cardMemberVO.phone_list = resultsList;//手机号
    },
    submitCreateGrant1 (formName) {//在线发放——新建发放——表格导入发放名单提交
      // 如何上传的信息有错误的 那么将不能再点击
      if (this.dialogVisible) return
      let status = true;
      if (this.activationData.status) {
        this.$refs[formName].validate(valid => {
          status = valid;
        });
      }
      if (status) {
        if (this.tooltipContentTwo == '') return this.$message.error('请上传文件')
        // 是否激活
        if (this.activationData.status) {
          // 激活
          this.cardMemberVO.status = 1;
          this.cardMemberVO.active_salesman = this.activationData.active_salesman;
          this.cardMemberVO.company = this.activationData.company;
        } else if (this.cardMemberVO.active_salesman) {
          // 没激活删除对应参数
          delete this.cardMemberVO.active_salesman;
          delete this.cardMemberVO.company;
          this.cardMemberVO.status = 0;
        }
        //防止重复提交——开始
        if (this.dialogVisible || this.submitImportCardKeysFlag) return
        this.submitImportCardKeysFlag = true
        //结束
        return API_coupon.grantTableAdd(this.cardMemberVO).then(
          res => {
            this.submitImportCardKeysFlag = false
            if (res.code == -1) {
              //上传错误
              this.dialogVisible = true;
              this.importErrorList = res.data;
              // this.publishOnlineDialog.displayFooter = false;
            } else {
              //上传成功
              this.$message.success('导入成功')
              this.cardMemberVO.phone_list = [];
              this.cardMemberVO.coupon_id = "";
              this.createGrantDielog = false;
              this.publishOnlineDialog.dismiss() //关闭弹窗
              this.getProvideLog();
            }
          }
        );
      } else this.$message.error("业务员名称和所属销售公司不能为空");
    }
  }
};
</script>

<style lang="scss" scoped>
.publishOnlineDialogDisableOk {
  ::v-deep .col-auto {
    .el-button--primary {
      background-color: #e6e6e6 !important;
      border-color: #e6e6e6 !important;
      color: #999 !important;
    }
  }
}

.on-line-box {
  ::v-deep .el-table__body-wrapper {
    overflow-y: auto;
    height: calc(100vh - 458px);
  }
}

.popinfo-box {
  ::v-deep .el-table__body-wrapper {
    overflow-y: auto;
    height: calc(100vh - 458px);
  }
}

.cash-coupon-manager-main-dialog {
  .el-tabs {
    // height: 80vh;
    // padding-bottom: 10px;

    .el-tabs__header {
      margin-bottom: 0;
    }

    .el-tab-pane {
      height: calc(80vh - 48px);
    }
  }
}

.view-card-dialog {
  .el-scrollbar {
    height: 70vh;
  }

  .el-scrollbar__view {
    padding: 0 !important;
  }

  .el-table__body-wrapper {
    overflow-x: hidden;
  }
}

.addCardPasswordDialog {
  .el-scrollbar__view {
    padding: 0 !important;
  }

  .el-tabs--card>.el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__content {
    padding: 16px;
  }
}

.add-camilo-file-box {
  white-space: nowrap;

  >.add-camilo-file {
    padding: 8px;
    background-color: rgba(231, 228, 228, 0.6);
    border-radius: 4px;
    border: none;
  }
}

.dialog-card-code {
  margin-top: 4px;
  position: absolute;
  right: 0;
}

.card-code-prompt-text {
  position: absolute;
  margin-top: -8px;
  font-size: 12px;
  color: red;
  z-index: 99;
}

.tooltip-text-32 {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.errorContent {
  height: 310px;
  overflow-y: auto;
}

.multipleTable {
  .el-table__body-wrapper {
    height: 280px;
    overflow-y: auto;
  }
}

::v-deep .updataExcel {
  .drop {
    margin: 0 10px;

    button {
      color: #FFFFFF;
      background-color: #1A43A9;
      border-color: #1A43A9;
    }
  }
}

::v-deep .el-table--border {
  // height: 500px !important;
  // height: 500px !important;
}

.tipContent {
  height: 100px;
  position: relative;

  &-text {
    // height: 80px;
    // line-height: 80px;
    padding-top: 10px;
  }

  &-button {
    position: absolute;
    bottom: 16px;
    right: 0;
  }
}

.tipBoxStyle {
  p {
    margin-bottom: .5rem;
  }
}

/deep/ .x-dialog {
  max-height: 93vh;
}
</style>
